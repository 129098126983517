import * as z from 'zod';
import {
  EMAIL_FORMAT,
  USER_INFO_REGEX,
  NAME_SPECIAL_CHARACTERS,
  LAST_NAME_SPECIAL_CHARACTERS,
  MAX_BASE_FIELD_LENGTH,
  ADDITIONAL_INFO_MESSAGE,
  MAX_EMAIL_LENGTH,
  MAX_ADDITIONAL_INFO_LENGTH,
  EMAILS_BLACK_LIST,
  COMPANY_SPECIAL_CHARACTERS,
  USER_INFO_REGEX_VALIDATION_MESSAGE,
  PHONE_FORMAT,
  MIN_PHONE_LENGTH,
  MAX_PHONE_LENGTH,
  REQUIRED,
} from '@/constants/errors';

export const validationSchema = z.object({
  firstName: z
    .string()
    .trim()
    .min(1, NAME_SPECIAL_CHARACTERS)
    .max(MAX_BASE_FIELD_LENGTH, `Max ${MAX_BASE_FIELD_LENGTH} symbols`)
    .regex(USER_INFO_REGEX, USER_INFO_REGEX_VALIDATION_MESSAGE),
  lastName: z
    .string()
    .trim()
    .min(1, LAST_NAME_SPECIAL_CHARACTERS)
    .max(MAX_BASE_FIELD_LENGTH, `Max ${MAX_BASE_FIELD_LENGTH} symbols`)
    .regex(USER_INFO_REGEX, USER_INFO_REGEX_VALIDATION_MESSAGE),
  phone: z
    .string({
      required_error: PHONE_FORMAT,
      invalid_type_error: PHONE_FORMAT,
    })
    .min(MIN_PHONE_LENGTH, PHONE_FORMAT)
    .max(MAX_PHONE_LENGTH, PHONE_FORMAT),
  email: z
    .string()
    .trim()
    .min(1, EMAIL_FORMAT)
    .max(MAX_EMAIL_LENGTH, `Max ${MAX_EMAIL_LENGTH} symbols`)
    .email(EMAIL_FORMAT)
    .refine((value) => !EMAILS_BLACK_LIST.some((email) => value.toLowerCase().includes(email)), EMAIL_FORMAT),
  companyName: z
    .string()
    .trim()
    .min(1, COMPANY_SPECIAL_CHARACTERS)
    .max(MAX_BASE_FIELD_LENGTH, `Max ${MAX_BASE_FIELD_LENGTH} symbols`),
  potentialRole: z.object(
    {
      value: z.string().min(1, REQUIRED),
    },
    {
      required_error: REQUIRED,
      invalid_type_error: REQUIRED,
    },
  ),
  additionalInfo: z
    .string()
    .trim()
    .min(1, ADDITIONAL_INFO_MESSAGE)
    .max(MAX_ADDITIONAL_INFO_LENGTH, `Max ${MAX_ADDITIONAL_INFO_LENGTH} symbols`),
});
